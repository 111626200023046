import angular from 'angular';
import 'angular-scroll';
import 'angular-sanitize';
import 'angular-animate';

require('./vendor/SweetAlert');
require('./vendor/sweetalert.min');
require('./vendor/airlst-sdk-1.1.1');

require('./airlst.js');

angular
  .module('app', [
    'oitozero.ngSweetAlert',
    'AirLST-SDK',
    'duScroll',
    'ngSanitize',
  ])
  .directive('ngEnter', [
    function () {
      return function (scope, element, attrs) {
        element.bind('keydown keypress', function (event) {
          if (event.which === 13) {
            scope.$apply(function () {
              scope.vm.loadRsvp();
            });

            event.preventDefault();
          }
        });
      };
    },
  ])

  .directive('ngSlideRight', [
    () => {
      return (scope, element) => {
        element.bind('keydown keypress', (event) => {
          if (event.which === 39) {
            scope.$apply(() => {
              scope.vm.slideRight();
            });
            event.preventDefault();
          }
        });
      };
    },
  ])

  .directive('ngSlideLeft', [
    () => {
      return (scope, element) => {
        element.bind('keydown keypress', (event) => {
          if (event.which === 37) {
            scope.$apply(() => {
              scope.vm.slideLeft();
            });
            event.preventDefault();
          }
        });
      };
    },
  ])

  .directive('ngEscape', [
    () => {
      return (scope, element) => {
        element.bind('keydown keypress', (event) => {
          if (event.which === 27) {
            scope.$apply(() => {
              scope.vm.toggleLightbox('');
            });
            event.preventDefault();
          }
        });
      };
    },
  ])

  .controller('AirLSTCtrl', [
    '$scope',
    '$location',
    'SweetAlert',
    '$document',
    'AirLSTSdkService',

    function ($scope, $location, SweetAlert, $document, AirLSTSdkService) {
      var vm = this;

      vm.isLightboxOpen = false;
      vm.currentLightboxOpen = '';
      vm.currentGallery = '';

      vm.imageIndex = null;

      vm.hasError = false;
      vm.loading = true;
      vm.submitting = false;
      vm.hasCode = false;
      vm.menucheckbox = false;

      vm.tab = 'survey';
      // vm.tab = 'done';
      // vm.tab = 'gallery';

      vm.mobileNavIsOpen = false;
      // vm.currentView = 'survey';
      // vm.companion = false;
      // vm.guestAnrede = '';
      // vm.representative;
      // vm.emailRepeat = false;
      // vm.addressOpen = false;
      // vm.hotelLimitRiched = false;

      vm.isGalleryVisible = false;

      var survey = angular.element(document.getElementById('survey'));
      var anmeldung = angular.element(document.getElementById('anmeldung'));
      var gallery = angular.element(document.getElementById('gallery'));

      vm._init = function () {
        AirLSTSdkService.companyUid = 'U14AP97';
        AirLSTSdkService.guestlistUid = 'D3L8XC6JFE';
        AirLSTSdkService.apiUrl = 'https://v1.api.airlst.com/lp';

        vm._resetRsvpInformation();
        vm._retrieveEventInformation();
        vm.rsvpInformation = {
          rsvp: {},
          contact: {},
        };
      };

      vm.setTab = function (tabId) {
        vm.tab = tabId;
        if (vm.tab == 'generalForm') {
          $document.scrollToElement(anmeldung);
        }
        if (vm.tab == 'survey') {
          console.log(vm.rsvpInformation.rsvp.status);
          $document.scrollToElement(survey);
        }
        if (vm.tab == 'gallery') {
          $document.scrollToElement(gallery);
        } else {
        }
        $scope.$applyAsync();
      };

      vm.isSet = function (tabId) {
        return vm.tab === tabId;
        $scope.$applyAsync();
      };

      vm.setImageIndex = (index) => {
        vm.imageIndex = index;
        $scope.$applyAsync();
      };

      vm.slideRight = () => {
        vm.imageIndex++;
        if (vm.imageIndex >= vm.gallery.length) {
          vm.imageIndex = 0;
        }
      };

      vm.slideLeft = () => {
        vm.imageIndex--;
        if (vm.imageIndex < 0) {
          vm.imageIndex = vm.gallery.length - 1;
        }
      };

      vm.toggleLightbox = (lightboxToToggle) => {
        if (lightboxToToggle === '') {
          vm.isLightboxOpen = false;
        } else {
          vm.isLightboxOpen = true;
          vm.currentLightboxOpen = lightboxToToggle;
        }
        $scope.$applyAsync();
      };

      vm._retrieveEventInformation = () => {
        vm.eventInformation = null;

        AirLSTSdkService.retrieveGuestlistInformation().then(
          (eventInformation) => {
            vm.eventInformation = eventInformation;
            $scope.$applyAsync();
          },
          () => {
            vm.hasError = true;

            swal(
              'Fehler',
              'Die Informationen zum gewünschten Event konnten nicht geladen werden.',
              'error',
            );
          },
        );
      };

      vm._checkRsvpActionOnUrl = () => {
        const match = $location.absUrl().match(/action=([A-Za-z0-9]+)/);

        if (match && match.length >= 2 && match[1]) {
          return match[1];
        } else {
          return false;
        }
      };

      vm.openDataPrivacy = function () {
        vm.overlay = true;
        vm.dataPrivacy = true;
        window.scroll(0, 0);
      };

      vm.openImprint = function () {
        vm.overlay = true;
        vm.imprint = true;
        window.scroll(0, 0);
      };

      vm.closeOverlay = function () {
        vm.overlay = false;
        vm.dataPrivacy = false;
        vm.imprint = false;
      };

      vm.createRsvp = (form) => {
        if (!vm._validateRegistrationForm(form)) {
          return;
        }
        vm.submitting = true;

        if (!vm.rsvpInformation.hasOwnProperty('rsvp')) {
          vm.rsvpInformation.rsvp = {};
        }

        vm.rsvpInformation.rsvp.status = 'confirmed';

        AirLSTSdkService.submitOpenRequest(
          vm._prepareRsvpInformationForApi(),
        ).then(
          (rsvpInfo) => {
            vm.submitting = false;
            vm.setTab('done');
            vm.isGalleryVisible = true;
          },
          ({ identifier }) => {
            vm.hasError = true;
            vm.submitting = false;

            switch (identifier) {
              case 'validation':
                SweetAlert.swal(
                  'Daten fehlerhaft',
                  'Bitte überprüfen Sie Ihre Daten.',
                  'error',
                );
                break;
              default:
                SweetAlert.swal(
                  'Fehler',
                  'Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte probieren Sie es noch einmal.',
                  'error',
                );
                break;
            }
          },
        );
      };

      vm.goToView = function (viewToGoTo) {
        vm.currentView = viewToGoTo;
        $scope.$applyAsync();
      };

      vm.setAnswerType = function (newAnswerType) {
        vm.answerType = newAnswerType;
      };

      vm._checkRsvpCodeOnUrl = function () {
        var match = $location.absUrl().match(/rsvp_code=([A-Za-z0-9]+)/);

        if (match && match.length >= 2 && match[1]) {
          vm.rsvpCode = match[1];
          vm.loadRsvp();
        } else {
          vm.loading = false;
        }
      };

      vm._checkRsvpActionOnUrl = function () {
        var match = $location.absUrl().match(/action=([A-Za-z0-9]+)/);

        if (match && match.length >= 2 && match[1]) {
          return match[1];
        } else {
          return false;
        }
      };

      vm._validateRegistrationForm = function (form) {
        vm.formValidationReg = true;

        if (!form.$valid) {
          SweetAlert.swal(
            'Fehlende Daten',
            'Bitte füllen Sie alle Pflichtfelder aus',
            'warning',
          );
          return false;
        } else {
          vm.formValidationReg = false;

          return true;
        }
      };

      vm._retrieveEventInformation = function () {
        vm.eventInformation = null;

        AirLSTSdkService.retrieveGuestlistInformation().then(
          function (eventInformation) {
            vm.eventInformation = eventInformation;
            switch (vm._checkRsvpActionOnUrl()) {
              case 'cancel':
                vm.cancelRsvp();
                break;
              case 'umfrage':
                vm.setTab('survey');

                break;
              case 'gallery':
                vm.setTab('gallery');

                break;
              default:
                break;
            }
            $scope.$applyAsync();
          },
          function () {
            vm.hasError = true;

            SweetAlert.swal(
              'Fehler',
              'Die Informationen zum gewünschten Event konnten nicht geladen werden',
              'error',
            );
          },
        );
      };

      vm._prepareRsvpInformationForApi = function () {
        return vm.rsvpInformation;
      };

      vm._prepareRsvpInformationFromApi = function (inData) {
        return inData;
      };

      vm._resetRsvpInformation = function () {
        vm.rsvpInformation = null;
      };

      vm.tagesvetranstaltung = [
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-100.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-101.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-102.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-104.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-106.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-107.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-114.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-118.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-12.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-126.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-127.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-129.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-130.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-131.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-132.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-133.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-135.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-14.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-140.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-142.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-145.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-147.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-148.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-149.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-150.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-151.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-16.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-172.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-178.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-18.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-180.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-182.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-185.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-186.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-189.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-194.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-195.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-198.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-20.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-201.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-210.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-213.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-214.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-215.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-218.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-229.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-238.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-24.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-240.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-242.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-243.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-244.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-245.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-248.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-256.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-258.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-259.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-260.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-261.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-263.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-264.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-268.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-27.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-271.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-278.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-281.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-290.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-302.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-311.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-313.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-314.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-316.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-321.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-329.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-33.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-330.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-331.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-332.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-333.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-334.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-365.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-366.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-367.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-369.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-370.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-372.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-377.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-379.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-38.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-380.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-381.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-383.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-384.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-385.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-387.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-391.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-392.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-393.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-394.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-396.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-398.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-399.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-401.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-403.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-404.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-405.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-407.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-408.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-411.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-414.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-415.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-416.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-417.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-418.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-426.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-427.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-43.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-44.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-45.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-51.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-55.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-60.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-62.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-68.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-69.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-70.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-71.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-72.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-73.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-74.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-77.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-80.jpg' },
        { url: './gallery/tagesvetranstaltung/VKB_JAT_23-86.jpg' },
      ];

      vm.tagesvetranstaltung_thumb = [
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-100.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-101.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-102.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-104.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-106.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-107.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-114.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-118.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-12.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-126.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-127.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-129.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-130.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-131.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-132.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-133.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-135.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-14.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-140.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-142.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-145.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-147.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-148.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-149.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-150.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-151.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-16.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-172.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-178.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-18.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-180.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-182.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-185.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-186.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-189.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-194.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-195.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-198.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-20.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-201.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-210.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-213.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-214.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-215.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-218.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-229.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-238.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-24.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-240.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-242.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-243.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-244.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-245.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-248.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-256.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-258.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-259.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-260.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-261.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-263.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-264.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-268.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-27.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-271.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-278.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-281.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-290.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-302.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-311.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-313.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-314.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-316.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-321.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-329.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-33.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-330.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-331.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-332.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-333.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-334.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-365.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-366.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-367.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-369.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-370.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-372.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-377.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-379.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-38.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-380.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-381.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-383.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-384.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-385.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-387.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-391.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-392.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-393.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-394.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-396.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-398.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-399.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-401.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-403.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-404.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-405.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-407.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-408.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-411.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-414.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-415.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-416.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-417.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-418.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-426.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-427.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-43.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-44.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-45.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-51.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-55.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-60.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-62.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-68.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-69.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-70.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-71.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-72.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-73.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-74.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-77.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-80.jpg' },
        { url: './gallery/tagesvetranstaltung_thumb/VKB_JAT_23-86.jpg' },
      ];

      vm.abendveranstaltung = [
        { url: './gallery/abendveranstaltung/VKB_JAT_23-435.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-440.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-450.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-452.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-453.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-460.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-461.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-464.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-465.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-466.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-468.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-469.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-470.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-472.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-473.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-475.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-476.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-478.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-479.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-480.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-481.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-483.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-485.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-488.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-491.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-493.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-494.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-510.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-512.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-513.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-514.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-515.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-516.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-517.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-518.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-520.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-524.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-525.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-526.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-527.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-528.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-530.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-534.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-537.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-538.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-539.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-541.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-542.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-544.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-545.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-547.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-551.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-552.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-553.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-554.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-556.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-558.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-559.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-560.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-561.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-562.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-566.jpg' },
        { url: './gallery/abendveranstaltung/VKB_JAT_23-574.jpg' },
      ];

      vm.abendveranstaltung_thumb = [
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-435.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-440.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-450.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-452.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-453.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-460.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-461.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-464.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-465.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-466.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-468.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-469.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-470.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-472.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-473.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-475.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-476.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-478.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-479.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-480.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-481.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-483.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-485.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-488.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-491.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-493.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-494.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-510.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-512.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-513.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-514.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-515.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-516.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-517.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-518.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-520.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-524.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-525.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-526.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-527.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-528.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-530.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-534.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-537.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-538.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-539.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-541.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-542.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-544.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-545.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-547.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-551.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-552.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-553.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-554.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-556.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-558.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-559.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-560.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-561.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-562.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-566.jpg' },
        { url: './gallery/abendveranstaltung_thumb/VKB_JAT_23-574.jpg' },
      ];

      vm.fotobox = [
        { url: './gallery/fotobox/20230213_204329_048_IMG_0004.jpg' },
        { url: './gallery/fotobox/20230213_204523_989_IMG_0007.jpg' },
        { url: './gallery/fotobox/20230213_205537_537_IMG_0021.jpg' },
        { url: './gallery/fotobox/20230213_205615_665_IMG_0022.jpg' },
        { url: './gallery/fotobox/20230213_205852_580_IMG_0026.jpg' },
        { url: './gallery/fotobox/20230213_205940_306_IMG_0027.jpg' },
        { url: './gallery/fotobox/20230213_210310_002_IMG_0033.jpg' },
        { url: './gallery/fotobox/20230213_210521_454_IMG_0036.jpg' },
        { url: './gallery/fotobox/20230213_210823_430_IMG_0039.jpg' },
        { url: './gallery/fotobox/20230213_210956_036_IMG_0041.jpg' },
        { url: './gallery/fotobox/20230213_211204_107_IMG_0043.jpg' },
        { url: './gallery/fotobox/20230213_211328_309_IMG_0045.jpg' },
        { url: './gallery/fotobox/20230213_211546_618_IMG_0049.jpg' },
        { url: './gallery/fotobox/20230213_211637_969_IMG_0050.jpg' },
        { url: './gallery/fotobox/20230213_211820_936_IMG_0054.jpg' },
        { url: './gallery/fotobox/20230213_212053_086_IMG_0057.jpg' },
        { url: './gallery/fotobox/20230213_212332_232_IMG_0061.jpg' },
        { url: './gallery/fotobox/20230213_212905_798_IMG_0068.jpg' },
        { url: './gallery/fotobox/20230213_213001_720_IMG_0069.jpg' },
        { url: './gallery/fotobox/20230213_213517_104_IMG_0075.jpg' },
        { url: './gallery/fotobox/20230213_213706_315_IMG_0077.jpg' },
        { url: './gallery/fotobox/20230213_213852_346_IMG_0080.jpg' },
        { url: './gallery/fotobox/20230213_214016_608_IMG_0082.jpg' },
        { url: './gallery/fotobox/20230213_214455_602_IMG_0088.jpg' },
        { url: './gallery/fotobox/20230213_215034_494_IMG_0094.jpg' },
        { url: './gallery/fotobox/20230213_215905_629_IMG_0101.jpg' },
        { url: './gallery/fotobox/20230213_220133_054_IMG_0103.jpg' },
        { url: './gallery/fotobox/20230213_221554_455_IMG_0106.jpg' },
        { url: './gallery/fotobox/20230213_222155_335_IMG_0112.jpg' },
        { url: './gallery/fotobox/20230213_222414_291_IMG_0116.jpg' },
        { url: './gallery/fotobox/20230213_222747_913_IMG_0117.jpg' },
        { url: './gallery/fotobox/20230213_222953_436_IMG_0120.jpg' },
        { url: './gallery/fotobox/20230213_223518_318_IMG_0127.jpg' },
        { url: './gallery/fotobox/20230213_224008_161_IMG_0133.jpg' },
        { url: './gallery/fotobox/20230213_224105_995_IMG_0134.jpg' },
        { url: './gallery/fotobox/20230213_231922_064_IMG_0154.jpg' },
        { url: './gallery/fotobox/20230213_232225_900_IMG_0159.jpg' },
        { url: './gallery/fotobox/20230213_232440_080_IMG_0162.jpg' },
        { url: './gallery/fotobox/20230213_232512_854_IMG_0163.jpg' },
        { url: './gallery/fotobox/20230213_232924_612_IMG_0168.jpg' },
        { url: './gallery/fotobox/20230213_233601_739_IMG_0177.jpg' },
        { url: './gallery/fotobox/20230213_233826_742_IMG_0181.jpg' },
        { url: './gallery/fotobox/20230213_234148_847_IMG_0184.jpg' },
        { url: './gallery/fotobox/20230213_234244_099_IMG_0185.jpg' },
        { url: './gallery/fotobox/20230213_234713_404_IMG_0192.jpg' },
        { url: './gallery/fotobox/20230213_235719_603_IMG_0205.jpg' },
        { url: './gallery/fotobox/20230213_235923_716_IMG_0209.jpg' },
        { url: './gallery/fotobox/20230214_000444_907_IMG_0210.jpg' },
        { url: './gallery/fotobox/20230214_001615_942_IMG_0217.jpg' },
        { url: './gallery/fotobox/20230214_003022_459_IMG_0236.jpg' },
        { url: './gallery/fotobox/20230214_003744_651_IMG_0238.jpg' },
        { url: './gallery/fotobox/20230214_012012_896_IMG_0258.jpg' },
        { url: './gallery/fotobox/20230214_015404_281_IMG_0278.jpg' },
        { url: './gallery/fotobox/20230214_024139_618_IMG_0290.jpg' },
      ];

      vm.fotobox_thumb = [
        { url: './gallery/fotobox_thumb/20230213_204329_048_IMG_0004.jpg' },
        { url: './gallery/fotobox_thumb/20230213_204523_989_IMG_0007.jpg' },
        { url: './gallery/fotobox_thumb/20230213_205537_537_IMG_0021.jpg' },
        { url: './gallery/fotobox_thumb/20230213_205615_665_IMG_0022.jpg' },
        { url: './gallery/fotobox_thumb/20230213_205852_580_IMG_0026.jpg' },
        { url: './gallery/fotobox_thumb/20230213_205940_306_IMG_0027.jpg' },
        { url: './gallery/fotobox_thumb/20230213_210310_002_IMG_0033.jpg' },
        { url: './gallery/fotobox_thumb/20230213_210521_454_IMG_0036.jpg' },
        { url: './gallery/fotobox_thumb/20230213_210823_430_IMG_0039.jpg' },
        { url: './gallery/fotobox_thumb/20230213_210956_036_IMG_0041.jpg' },
        { url: './gallery/fotobox_thumb/20230213_211204_107_IMG_0043.jpg' },
        { url: './gallery/fotobox_thumb/20230213_211328_309_IMG_0045.jpg' },
        { url: './gallery/fotobox_thumb/20230213_211546_618_IMG_0049.jpg' },
        { url: './gallery/fotobox_thumb/20230213_211637_969_IMG_0050.jpg' },
        { url: './gallery/fotobox_thumb/20230213_211820_936_IMG_0054.jpg' },
        { url: './gallery/fotobox_thumb/20230213_212053_086_IMG_0057.jpg' },
        { url: './gallery/fotobox_thumb/20230213_212332_232_IMG_0061.jpg' },
        { url: './gallery/fotobox_thumb/20230213_212905_798_IMG_0068.jpg' },
        { url: './gallery/fotobox_thumb/20230213_213001_720_IMG_0069.jpg' },
        { url: './gallery/fotobox_thumb/20230213_213517_104_IMG_0075.jpg' },
        { url: './gallery/fotobox_thumb/20230213_213706_315_IMG_0077.jpg' },
        { url: './gallery/fotobox_thumb/20230213_213852_346_IMG_0080.jpg' },
        { url: './gallery/fotobox_thumb/20230213_214016_608_IMG_0082.jpg' },
        { url: './gallery/fotobox_thumb/20230213_214455_602_IMG_0088.jpg' },
        { url: './gallery/fotobox_thumb/20230213_215034_494_IMG_0094.jpg' },
        { url: './gallery/fotobox_thumb/20230213_215905_629_IMG_0101.jpg' },
        { url: './gallery/fotobox_thumb/20230213_220133_054_IMG_0103.jpg' },
        { url: './gallery/fotobox_thumb/20230213_221554_455_IMG_0106.jpg' },
        { url: './gallery/fotobox_thumb/20230213_222155_335_IMG_0112.jpg' },
        { url: './gallery/fotobox_thumb/20230213_222414_291_IMG_0116.jpg' },
        { url: './gallery/fotobox_thumb/20230213_222747_913_IMG_0117.jpg' },
        { url: './gallery/fotobox_thumb/20230213_222953_436_IMG_0120.jpg' },
        { url: './gallery/fotobox_thumb/20230213_223518_318_IMG_0127.jpg' },
        { url: './gallery/fotobox_thumb/20230213_224008_161_IMG_0133.jpg' },
        { url: './gallery/fotobox_thumb/20230213_224105_995_IMG_0134.jpg' },
        { url: './gallery/fotobox_thumb/20230213_231922_064_IMG_0154.jpg' },
        { url: './gallery/fotobox_thumb/20230213_232225_900_IMG_0159.jpg' },
        { url: './gallery/fotobox_thumb/20230213_232440_080_IMG_0162.jpg' },
        { url: './gallery/fotobox_thumb/20230213_232512_854_IMG_0163.jpg' },
        { url: './gallery/fotobox_thumb/20230213_232924_612_IMG_0168.jpg' },
        { url: './gallery/fotobox_thumb/20230213_233601_739_IMG_0177.jpg' },
        { url: './gallery/fotobox_thumb/20230213_233826_742_IMG_0181.jpg' },
        { url: './gallery/fotobox_thumb/20230213_234148_847_IMG_0184.jpg' },
        { url: './gallery/fotobox_thumb/20230213_234244_099_IMG_0185.jpg' },
        { url: './gallery/fotobox_thumb/20230213_234713_404_IMG_0192.jpg' },
        { url: './gallery/fotobox_thumb/20230213_235719_603_IMG_0205.jpg' },
        { url: './gallery/fotobox_thumb/20230213_235923_716_IMG_0209.jpg' },
        { url: './gallery/fotobox_thumb/20230214_000444_907_IMG_0210.jpg' },
        { url: './gallery/fotobox_thumb/20230214_001615_942_IMG_0217.jpg' },
        { url: './gallery/fotobox_thumb/20230214_003022_459_IMG_0236.jpg' },
        { url: './gallery/fotobox_thumb/20230214_003744_651_IMG_0238.jpg' },
        { url: './gallery/fotobox_thumb/20230214_012012_896_IMG_0258.jpg' },
        { url: './gallery/fotobox_thumb/20230214_015404_281_IMG_0278.jpg' },
        { url: './gallery/fotobox_thumb/20230214_024139_618_IMG_0290.jpg' },
      ];

      vm._init();
      vm._checkRsvpCodeOnUrl();
      vm._checkRsvpActionOnUrl();
    },
  ]);
